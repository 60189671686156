<template>
	<div class="d-flex flex-column comparemapsmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-3 px-3 bottomborder" ref="header">
			<div class="d-flex align-start justify-space-between mb-2">
				<div class="d-flex flex-column">
					<div>
						<div class="settings-title fw-bold">{{ $t('panels.comparemapspanel.title') }}</div>
						<div class="settings-subtitle fw-regular fs-sm">{{ $t('panels.comparemapspanel.subtitle') }}</div>
					</div>
				</div>

				<!--
				<v-btn @click="downloadMaps" depressed icon height="32" width="32" class="closebtn ml-auto mr-1" title="Download Maps">
					<v-icon size="0.9rem">fas fa-download</v-icon>
				</v-btn>
				-->
				<div class="d-flex flex-nowrap align-center">
					<map-get-feature-info class="closebtn ml-auto ma-0 mr-2" />
					<map-download-pdf :bg="'bg-low'" />

					<!-- style="min-width: 36px !important; height: 36px !important; width: 36px !important"
						class="closebtn ma-0 ml-1"
						title="Download Maps" -->

					<!-- <v-btn @click="closePanel" depressed icon height="32" width="32" class="closebtn ml-auto" title="Cerrar">
					<v-icon class="fw-bold" size="0.9rem"> far fa-times </v-icon>
				</v-btn> -->
				</div>
			</div>
		</div>

		<!--<div class="raster-tools d-flex">
			<map-get-feature-info />
		</div>-->
		<div class="lists pb-0" style="min-height: 30%">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- Map 1 Raster Layers -->
				<div v-if="useSatelliteImages">
					<v-list-group :id="rasterId1" :ripple="false" v-model="isOpened.layersrasterCompareMap1">
						<template #activator>
							<v-list-item-title class="maintitle fw-bold ml-n4" :title="$t('panels.comparemapspanel.satelliteimage1')">{{
								$t('panels.comparemapspanel.satelliteimage1')
							}}</v-list-item-title>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.layersrasterCompareMap1 ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.comparemapspanel.satelliteimage1')"
								>fak fa-satellite</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.layersrasterCompareMap1 }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<raster-tools ref="rastertools" />
						</v-list-item>

						<v-list-item class="pa-0 mx-4">
							<layers-raster-list :rasterlist="rasterId1" :key="1" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>

				<!-- Map 2 Raster Layers -->
				<div>
					<v-list-group :id="rasterId2" :ripple="false" v-model="isOpened.layersrasterCompareMap2">
						<template #activator>
							<v-list-item-title class="maintitle fw-bold ml-n4" :title="$t('panels.comparemapspanel.satelliteimage2')">{{
								$t('panels.comparemapspanel.satelliteimage2')
							}}</v-list-item-title>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.layersrasterCompareMap2 ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.comparemapspanel.satelliteimage2')"
								>fak fa-satellite</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.layersrasterCompareMap2 }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<raster-tools ref="rastertools" />
						</v-list-item>

						<v-list-item class="pa-0 mx-4">
							<layers-raster-list :rasterlist="rasterId2" ref="rasterlist" :key="2" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>
			</v-list>
		</div>
	</div>
</template>

<script>
//import { isSuperAdmin, search } from '@/api/common';

import LayersRasterList from './LayersRasterList';
import jsonFileImage from '@/assets/images/json_file.png';
import MapGetFeatureInfo from '@/components/map/sections/map/subcomponents/MapGetFeatureInfo';
import RasterTools from '@/components/map/sections/info/settings/RasterTools.vue';

import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import MapDownloadPdf from '@/components/map/sections/map/subcomponents/MapDownloadPDF';

import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
import constants from '@/utils/constants';
//import GeoJsonTools from './GeoJsonTools';
//import { SETTINGS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'comparemapsmappanel',
	data() {
		return {
			jsonFile_icon: jsonFileImage,
			rasterId1: constants.RASTER.MAP1,
			rasterId2: constants.RASTER.MAP2
			//rasterlist2: 'rasterlist2'
		};
	},

	components: {
		LayersRasterList,
		MapGetFeatureInfo,
		RasterTools,
		MapDownloadPdf
	},

	computed: {
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		},
		useSatelliteImages() {
			return this.$store.getters.getPermissions['APP_SATELLITE'];
		},
		useSatelliteImagesCompareMap() {
			return this.$store.getters.getPermissions['APP_COMPARE_SATELLITE'];
		}
	},
	watch: {
		isOpened: {
			handler() {
				//console.log('debug isopened comparemapsmappanel', oldValue, newValue);
			}
		},
		'isOpened.layersrasterCompareMap1': {
			handler(newValue) {
				this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1 = newValue;
			},
			deep: true
		},
		'isOpened.layersrasterCompareMap2': {
			handler(newValue) {
				this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2 = newValue;
			},
			deep: true
		}
	},
	beforeCreate() {
		this.$store.commit('setActivePanel', { panelId: COMPAREMAPS_PANEL_ID });
		this.$store.commit('setPanelsClosed', false);
	},
	created() {},
	beforeMount() {},
	mounted() {},
	updated() {},

	methods: {
		closePanel() {
			if (this.$store.state.mappanel.rasterLayersCompareMap1 !== null) {
				const activeLayerCompareMap1 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap1).find(
					(layer) => this.$store.state.mappanel.rasterLayersCompareMap1[layer]['active']
				);
				if (activeLayerCompareMap1 !== undefined) {
					this.$store.state.mappanel.rasterLayersCompareMap1[activeLayerCompareMap1]['active'] = false;
					ol2map.setVisibility(activeLayerCompareMap1, false);
				}
			}
			if (this.$store.state.mappanel.rasterLayersCompareMap2 !== null) {
				const activeLayerCompareMap2 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap2).find(
					(layer) => this.$store.state.mappanel.rasterLayersCompareMap2[layer]['active']
				);
				if (activeLayerCompareMap2 !== undefined) {
					this.$store.state.mappanel.rasterLayersCompareMap2[activeLayerCompareMap2]['active'] = false;
					ol2map
						.getMapCompareInstance()
						.getLayers()
						.forEach(function (layer) {
							if (layer.get('id') === activeLayerCompareMap2) {
								layer.setVisible(false);
							}
						});
				}
			}
			this.$store.commit('setIsRasterCompareMap1Active', false);
			this.$store.commit('setIsRasterCompareMap2Active', false);
			this.$store.commit('setIsRasterActive', false);

			this.$store.state.settingsPanel.isOpened.layersrasterSettings = false;
			this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1 = false;
			this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2 = false;

			/* var divMapContainer = document.getElementById('div-map-compare-container');
			if (divMapContainer) {
				divMapContainer.style.display = 'none';
			}

			this.$store.commit('setActivePanel', { panelId: SETTINGS_PANEL_ID }); */
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';

.comparemapsmappanel {
	& .list-icon {
		color: #000;

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-size: 1rem !important;
		}
	}

	& .settings-title {
		font-size: 1.3rem !important;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}

	& .settings-subtitle {
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33 !important;
		letter-spacing: normal;
		color: var(--gris-500) !important;
		margin-top: 2px !important;
	}
	.btndownloadpdf {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--low);
	}
}

.bottomborder {
	border-bottom: 1px solid var(--moderate);
}

& .closebtn {
	background-color: var(--low) !important;

	& i {
		color: var(--primary);
	}

	&:hover {
		& i {
			color: var(--mapfre-accent);
		}
	}
}
</style>
